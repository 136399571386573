import './App.css';
import mpegts from 'mpegts.js';
import {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent, createTheme, CssBaseline,
    FormControl, Grid,
    InputAdornment,
    TextField, ThemeProvider,
    Typography
} from "@mui/material";

import noSignal from './no_signal.jpg';

function App() {

    const [ifLeftLostSignal, setIfLeftLostSignal] = useState(true);
    const [ifRightLostSignal, setIfRightLostSignal] = useState(true);

    useEffect(() => {
        if (mpegts.getFeatureList().mseLivePlayback) {
            let leftVideo = document.getElementById('leftVideo');
            let player = mpegts.createPlayer({
                type: 'flv',  // could also be mpegts, m2ts, flv
                isLive: true,
                url: 'https://rtmplive.zhost.app/live/leftvideo.flv'
            });
            player.attachMediaElement(leftVideo);
            player.muted = true;
            player.load();

            player.on(mpegts.Events.MEDIA_INFO, (err, errdet) => {
                setIfLeftLostSignal(false);
            });


            let rightVideo = document.getElementById('rightVideo');
            let player2 = mpegts.createPlayer({
                type: 'flv',  // could also be mpegts, m2ts, flv
                isLive: true,
                url: 'https://rtmplive.zhost.app/live/TEST.flv'
            });
            player2.attachMediaElement(rightVideo);
            player2.muted = true;
            player2.load();

            window.addEventListener('pageshow', function () {
                player.play();
                player2.play();
            });
            player2.on(mpegts.Events.MEDIA_INFO, (err, errdet) => {
                setIfRightLostSignal(false);
            });
        }
        return () => {
        }
    }, []);


    const defaultTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline/>
                <div>
                    <Card sx={{margin: 2}}>
                        <CardContent>
                            <Typography variant={"h5"}>設定</Typography>

                            <Box style={{marginTop: 10}}/>
                            <FormControl fullWidth>
                                <TextField variant="standard" label={"直播來源"}
                                           sx={{width: "100%", maxWidth: 360}}
                                           value={"rtmp://" + window.location.hostname + "/live"}
                                           InputProps={{
                                               startAdornment: <InputAdornment
                                                   position="start">直播 URL</InputAdornment>,
                                           }}
                                           onChange={(e) => {

                                           }}/>
                            </FormControl>

                            <Box style={{marginTop: 10}}/>
                            <FormControl fullWidth>
                                <TextField variant="standard" label={"連線資訊"}
                                           sx={{width: "100%", maxWidth: 360}}
                                           value={"leftvideo"}
                                           InputProps={{
                                               startAdornment: <InputAdornment
                                                   position="start">金鑰</InputAdornment>,
                                           }}
                                           onChange={(e) => {

                                           }}/>
                            </FormControl>
                        </CardContent>
                    </Card>
                    <Grid container>
                        <Grid item xs={6}>
                            <Box margin={2} sx={{position: "relative"}}>
                                <Typography variant={"h5"}>來自 RTMP直播 的影像</Typography>
                                {ifLeftLostSignal ? <img src={noSignal} style={{width: "100%"}}/> : <></>}
                                <video id="leftVideo" style={{width: "100%", position: "absolute"}}></video>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box margin={2} sx={{position: "relative"}}>
                                <Typography variant={"h5"}>來自 測試 直播的影像</Typography>
                                {ifRightLostSignal ? <img src={noSignal} style={{width: "100%"}}/> : <></>}
                                <video id="rightVideo" style={{width: "100%", position: "absolute"}}></video>
                            </Box>
                        </Grid>
                    </Grid>

                </div>
            </ThemeProvider>
        </>
    );
}

export default App;
